<template>
  <v-app>
    <!--layout-app-app-bar /-->
    <!--layout-pos-drawer :key="keyRender" /-->

    <v-main class="">
      
      <router-view :key="$route.fullPath" />

      <!--layout-core-footer /-->
      <div>
        
        <print-component></print-component>
      </div>
    </v-main>
  </v-app>
</template>
  
  <style>
.v-main {
  background: #fafafa !important;
}
.v-main__wrap {
  padding-top: 18px !important;
}
.v-item--active {
  font-weight: 400 !important;
  font-size: 0.875rem !important;
}
.v-btn-toggle > .v-btn.v-btn {
  opacity: 1;
}
</style>
  
  <script>

export default {
  name: "PosIndex",
  components: {},
  data: () => ({
    expandOnHover: false,
    keyRender: 0,
    ws: null,
  }),

  created() {
    this.$auth.loadInfoSession();

  },
  mounted() {},
  methods: {},
};
</script>